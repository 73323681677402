'use client';

import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: var(--size-1);
  transition: transform 0.3s var(--animation-bezier);
`;

export const StyledFontAwesomeIconRotated = styled(StyledFontAwesomeIcon)`
  transform: rotate(315deg);
`;

const MinorStyles = css`
  background: none;
  color: var(--dark-grey-4);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: var(--size-half);
  cursor: pointer;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  border: none;

  & > span {
    display: inline-flex;
    align-items: center;
    gap: var(--size-half);
    margin: 0;
    padding: 0;
  }

  &:hover {
    color: var(--c-yellow-2);
  }

  &:hover > span,
  &:focus > span {
    text-decoration: underline;
  }

  &:hover .minor-icon-right,
  &:focus .minor-icon-right {
    transform: translateX(var(--size-nudge));
  }

  &:hover .minor-icon-up-right,
  &:focus .minor-icon-up-right {
    transform: translate(var(--size-nudge), calc(-1 * var(--size-nudge)))
      rotate(315deg);
  }

  &:focus {
    outline: 1px solid var(--c-browser-blue-1);
  }

  &:active {
    color: var(--c-yellow-3);
  }
`;

const BaseMinor = styled.div`
  ${MinorStyles}
`;

export const StyledButtonMinor = styled(BaseMinor).attrs({
  as: 'button',
})``;

export const StyledLinkMinor = styled(BaseMinor).attrs({
  as: 'span',
})``;

export const StyledAnchorMinor = styled(BaseMinor).attrs({
  as: 'a',
})``;
