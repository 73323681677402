'use client';

// Third Party Plugins
import React from 'react';
// Types
import { MinorTagProps } from './types';
// Styles
import { StyledTagMinor } from './styles';

const MinorTag: React.FC<MinorTagProps> = ({ text }) => (
  <StyledTagMinor>{text}</StyledTagMinor>
);

export default MinorTag;
