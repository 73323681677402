// Third Party Plugins
import React from 'react';
// Types
import type { TagProps } from './types';
// Custom Components
import MajorTag from '../MajorTag';
import MinorTag from '../MinorTag';

function Tag({ type, text, color }: TagProps) {
  switch (type) {
    case `minor`:
      return <MinorTag text={text} />;
    case `primary`:
    default:
      return <MajorTag text={text} color={color} />;
  }
}

export default Tag;
