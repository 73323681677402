'use client';

// Third Party Plugins
import React, { useState } from 'react';
import Image from 'next/image';
// Types
import type { DefaultThumbnailImageProps } from './types';
// Images
const defaultThumbnail = '/assets/images/default-aef-thumbnail-logo.jpg';

function DefaultThumbnailImage({
  borderRadius,
  className,
}: DefaultThumbnailImageProps) {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleError = () => {
    console.error('Image failed to load:', defaultThumbnail);
    setImageLoaded(false);
  };

  return (
    <>
      {imageLoaded ? (
        <Image
          src={defaultThumbnail}
          alt="AEF Logo"
          width="100"
          height="100"
          style={{ borderRadius, objectFit: 'cover' }}
          className={className}
          onError={handleError}
        />
      ) : (
        <div>No default image</div>
      )}
    </>
  );
}

export default DefaultThumbnailImage;
