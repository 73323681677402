'use client';

import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

const baseButtonStyles = css`
  background: var(--c-yellow-1);
  color: var(--dark-grey-4);
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--size-dual-nudge) var(--size-1h);
  border-radius: var(--radius-sm);
  gap: var(--size-half);
  cursor: pointer;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  border: none;

  & > span {
    display: inline-flex;
    align-items: center;
    gap: var(--size-half);
  }

  &:hover {
    transform: scale(1.05);
    box-shadow:
      rgba(0, 0, 0, 0.18) 0px 2px 4px,
      rgba(0, 0, 0, 0.13) 0px 4px 14px;
  }

  &:hover > span,
  &:focus > span {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px solid var(--c-browser-blue-1);
  }

  &:active {
    color: var(--c-yellow-3);
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: var(--size-1);
  transition: transform 0.3s var(--animation-bezier);
`;

export const StyledFontAwesomeIconRotated = styled(StyledFontAwesomeIcon)`
  transform: rotate(315deg);
`;

// Styled component for Button
export const StyledButtonSolid = styled.button`
  ${baseButtonStyles}
`;

// Styled component for Link
export const StyledLinkSolid = styled(Link)`
  ${baseButtonStyles};
  background: var(--c-yellow-1);
  flex-direction: row;
  padding: var(--size-dual-nudge) var(--size-1h);
  border-radius: var(--radius-sm);
`;

// Styled component for Anchor
export const StyledAnchorSolid = styled.a`
  ${baseButtonStyles}
`;

// Minor Button Styles
export const StyledButtonMinor = styled(StyledButtonSolid)`
  /* Additional minor button styles */
`;

// Minor Link Styles
export const StyledLinkMinor = styled(StyledLinkSolid)`
  /* Additional minor link styles */
`;

// Minor Anchor Styles
export const StyledAnchorMinor = styled(StyledAnchorSolid)`
  /* Additional minor anchor styles */
`;
