'use client';
import styled from 'styled-components';

// TODO: check this as there was an unresolved git conflict
export const StyledPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-1);

  @media screen and (min-width: 768px) {
    flex-direction: row; /* Display cards side by side on larger screens */
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
    justify-content: space-between; /* Distribute cards evenly across the container */
  }

  @media screen and (min-width: 1041px) {
    flex-direction: row; /* Display cards side by side on larger screens */
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
    justify-content: space-between; /* Distribute cards evenly across the container */
  }
`;
