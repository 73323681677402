'use client';
import styled from 'styled-components';

export const StyledTextContainer = styled.div<{ $type?: string }>`
  display: flex;
  flex-direction: column;
`;

export const StyledTextHeading = styled.h2<{ $type?: string }>`
  color: var(--dark-grey-4);
  font-size: var(--size-1n);
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
`;
