'use client';
import styled from 'styled-components';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-2h);
  width: 100%;
`;

export const StyledNewsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--size-3dn);
  width: 100%;
  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    gap: var(--size-3);
    max-width: 1200px; /* Match the max-width of StyledLayoutContainer */
    margin: 0 auto; /* Center the container */
    padding: 0 var(--size-1);
  }
`;

export const StyledNewsSection = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure sections wrap to next line if there's not enough space */
  gap: var(--size-2h); /* Adjust gap as needed */
  width: 100%;
`;

export const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const StyledSectionTitle = styled.h2`
  color: var(--c-dark-grey-3);
  text-transform: capitalize;
  margin: 0 auto;
`;

export const StyledProvideCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--size-1);
  justify-items: center;
  width: 100%;
`;

export const StyledPostsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--size-3dn);
  width: 100%;
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  gap: var(--size-dual-nudge);
`;
