'use client';

// Third Party Plugins
import React from 'react';
// Types
import { MajorTagProps } from './types';
// Styles
import { StyledTagMajor } from './styles';

const MajorTag: React.FC<MajorTagProps> = ({ text, color }) => (
  <StyledTagMajor $color={color}>{text}</StyledTagMajor>
);

export default MajorTag;
