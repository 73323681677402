'use client';

// Third Party Plugins
import React from 'react';
// Types
import { CardContentProps } from './types';
// Styles
import { StyledBookContentContainer, StyledMetaContainer } from './styles';

const BookCardContent: React.FC<CardContentProps> = ({
  cardType,
  isbn,
  publisher,
  author,
  dateOfPublication,
  category,
}) => {
  return (
    <StyledBookContentContainer
      style={
        cardType === `pastEvent`
          ? {
              borderTop: `none`,
            }
          : undefined
      }
    >
      <StyledMetaContainer>
        {author && (
          <span style={{ fontSize: `15px`, color: 'black' }}>
            <strong>Author:</strong> {author}
          </span>
        )}
        {category && (
          <span style={{ fontSize: `15px`, color: 'black' }}>
            <strong>Subject:</strong> {category}
          </span>
        )}

        {isbn && (
          <span style={{ fontSize: `15px`, color: `black` }}>
            <strong>ISBN:</strong> {isbn}
          </span>
        )}
        {publisher && (
          <span style={{ fontSize: `15px`, color: `black` }}>
            <strong>Publisher:</strong> {publisher}
          </span>
        )}
        {dateOfPublication && (
          <span style={{ fontSize: `15px`, color: `black` }}>
            <strong>Date Of Publication:</strong> {dateOfPublication}
          </span>
        )}
      </StyledMetaContainer>
    </StyledBookContentContainer>
  );
};

export default BookCardContent;
