'use client';

// Third Party Plugins
import React from 'react';
// Types
import { HomeContentCardTextProps } from './types';
// Utilites
import truncateText from '@utils/truncateText/truncateText';
// Styles
import { StyledTextContainer, StyledTextHeading } from './styles';

const ContentCardText: React.FC<HomeContentCardTextProps> = ({
  type,
  heading,
  excerpt,
  charLimitHeading,
  charLimitExcerpt,
  isOnHomePage,
}) => {
  switch (type) {
    case 'news':
      return (
        <StyledTextContainer>
          <StyledTextHeading title={heading}>
            {truncateText(charLimitHeading, heading)}
          </StyledTextHeading>
          {!isOnHomePage && (
            <p title={excerpt}>{truncateText(charLimitExcerpt, excerpt)}</p>
          )}
        </StyledTextContainer>
      );
    default:
      return (
        <StyledTextContainer $type={type}>
          <StyledTextHeading $type={type} title={heading}>
            {truncateText(charLimitHeading, heading)}
          </StyledTextHeading>
          <p title={excerpt}>{truncateText(charLimitExcerpt, excerpt)}</p>
        </StyledTextContainer>
      );
  }
};

export default ContentCardText;
