'use client';

// Third Party Plugins
import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { VisuallyHidden } from '@reach/visually-hidden';
// Types
import { MinorButtonProps } from './types';
// Styles
import {
  StyledAnchorMinor,
  StyledButtonMinor,
  StyledFontAwesomeIcon,
  StyledLinkMinor,
} from './styles';

const MinorButton: React.FC<MinorButtonProps> = ({
  actionType = 'button',
  link,
  action = () => false,
  disabled,
  children,
  ...props
}) => {
  const renderIcon = (rotation: string) => (
    <StyledFontAwesomeIcon
      className={`minor-icon-${rotation}`}
      icon={faArrowRight}
    />
  );

  const renderContent = (rotation: string) => (
    <>
      <span>{children}</span>
      {renderIcon(rotation)}
      {rotation === 'up-right' && (
        <VisuallyHidden>opens in a new window</VisuallyHidden>
      )}
    </>
  );
  // if (disabled) {
  //   return <span>{children}</span>;
  // }

  switch (actionType) {
    case 'link':
    case 'internal':
      return (
        <StyledLinkMinor {...props}>{renderContent('right')}</StyledLinkMinor>
      );
    case 'anchor':
    case 'external':
      return (
        <StyledAnchorMinor
          href={link || '#'}
          target="_blank"
          rel="noreferrer noopener"
          {...props}
        >
          {renderContent('up-right')}
        </StyledAnchorMinor>
      );
    case 'button':
    default:
      return (
        <StyledButtonMinor onClick={action} {...props}>
          <span>{children}</span>
        </StyledButtonMinor>
      );
  }
};

export default MinorButton;
